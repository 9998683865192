import { useConfig } from 'context/app-config';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPageName } from 'redux-stores/slices/uiSlice';
import type { AppDispatch } from 'redux-stores/store';

interface RouteWithAnalyticsProps {
  component: React.ComponentType<any>;
  pageName?: string;
}

function RouteWithEvent({ component: Component, pageName }: RouteWithAnalyticsProps) {
  const dispatch = useDispatch<AppDispatch>();
  const config = useConfig();

  useEffect(() => {
    // Trigger an analytics event on route change
    if (pageName) {
      dispatch(setPageName(pageName));
      config?.event?.screenEvent?.(pageName);
    }
  }, [pageName]);

  return <Component />;
}

export default RouteWithEvent;
