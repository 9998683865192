import { QueryClientProvider } from '@tanstack/react-query';
import Avo from 'analytics/Avo';
import { useDynamicFonts } from 'assets/fonts';
import { useDynamicIcons } from 'assets/icons';
import { useTheme } from 'assets/themes';
import { ConfirmationDialogProvider } from 'components/molecules/ConfirmationModal';
import { SpinnerLoader } from 'components/molecules/Loaders';
import { FineryPricingScreen, Home, Login, NewOrder, Pricing, protectedRoutes, publicRoutes, Support } from 'constant/routes';
import ConfigProvider, { useConfig } from 'context/app-config';
import { useDynamicLanguage } from 'hooks/useDynamicLanguage';
import useImagePreloader from 'hooks/useImagePreloader';
import { usePartner } from 'hooks/usePartner';
import usePrevLocation from 'hooks/usePrevLocation';
import useSplashScreenDisplay from 'hooks/useSplashScreenDisplay';
import { useGlobalConfig } from 'pages/queries/global.queries';
import { useCustomerPreferences } from 'pages/queries/user.queries';
import React, { type FC, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Navigate, Route, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { selectCustomer } from 'redux-stores/slices/authSlice';
import { ThemeProvider } from 'styled-components';
import useCreateAnimatableRoutes from 'utils/animation/hooks/useCreateAnimatableRoutes';
import RoutesTransitioner from 'utils/animation/RoutesTransitioner';
import { withComponentPreloading } from 'utils/bundle';
import { queryClient } from 'utils/react-query';
import ProtectedRoutes from 'utils/router/ProtectedRoutes';
import { trackEvent } from 'wrappers/reporting';
import RouteWithEvent from 'wrappers/RouteWithEvent';

function usePageViews() {
  const location = useLocation();
  const prevLocation = usePrevLocation(location);

  useEffect(() => {
    if (!location.search.includes('step')) {
      trackEvent(`Viewed ${location.pathname} Page`);
      Avo.pageview({ screen: location.pathname, previousScreen: prevLocation.pathname });
    }
  }, [location, prevLocation.pathname]);
}

function PreloadRequestsWithoutEffect() {
  const customer = useSelector(selectCustomer);

  if (!customer) {
    Login.preload();
  }

  // preloading customer information.
  // prevent re-render counts
  // TODO: handle this case for App Washmen.
  useGlobalConfig();
  useCustomerPreferences();

  usePageViews();

  useSplashScreenDisplay();

  usePartner();

  useConfig();

  return null;
}

const wrapWithRouteWithEvent =
  (Component: FC, pageName?: string): FC =>
  (props) => <RouteWithEvent component={Component} pageName={pageName} {...props} />;

function RouterWrapper() {
  const { createAnimatableRoutes } = useCreateAnimatableRoutes();

  // TODO: enable={config?.shouldEnableRouteTransition} causing re-render
  // need to rethink the approach here

  const Routes = useMemo(
    () => (
      <RoutesTransitioner destroy skipIntialRoute="/home" timing="ease" animation="slide" enable>
        {/* Protected Routes */}
        <Route element={<ProtectedRoutes />}>
          {createAnimatableRoutes(
            protectedRoutes.map(({ path, Component, pageName }) => (
              <Route key={path} path={path} Component={wrapWithRouteWithEvent(Component, pageName)} />
            ))
          )}
        </Route>

        {/* Public Routes */}
        <Route>
          {createAnimatableRoutes(
            publicRoutes.map(({ path, Component, pageName }) => (
              <Route key={path} path={path} Component={wrapWithRouteWithEvent(Component, pageName)} />
            ))
          )}
        </Route>

        {/* Default Navigation */}
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/reload" element={<Navigate to="/home" />} />
      </RoutesTransitioner>
    ),
    [createAnimatableRoutes]
  );

  return (
    <>
      {Routes}
      <PreloadRequestsWithoutEffect />
    </>
  );
}

function App() {
  const theme = useTheme();

  useDynamicIcons();
  useDynamicFonts(theme);
  useImagePreloader();

  useDynamicLanguage();

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ConfirmationDialogProvider>
          <ToastContainer hideProgressBar position="top-center" />
          <ConfigProvider>
            <React.Suspense fallback={<SpinnerLoader isDelayed />}>
              <Router>
                <RouterWrapper />
              </Router>
            </React.Suspense>
          </ConfigProvider>
        </ConfirmationDialogProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
}

export default withComponentPreloading({ components: [Home, NewOrder, Pricing, FineryPricingScreen, Support] })(App);
