import { useConfig } from 'context/app-config';
import React from 'react';
import type { ButtonProps } from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useSelector } from 'react-redux';
import { selectPageName } from 'redux-stores/slices/uiSlice';

import StyledButton from './Button.styles';

interface Props extends ButtonProps {
  isLoading?: boolean;
  hasFixedHeight?: boolean;
  textColor?: string;
  backgroundColor?: string;
  borderColor?: string;
}

const Button: React.FC<Props> = ({
  isLoading = false,
  children,
  variant,
  hasFixedHeight = true,
  textColor,
  backgroundColor,
  onClick: externalOnClick,
  ...props
}) => {
  const pageName = useSelector(selectPageName);
  const config = useConfig();
  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    let buttonName: string = '';
    // use temporary solution to get button name
    if (Array.isArray(children)) {
      buttonName = children[0] as string;
    }
    config?.event?.buttonEvent?.(buttonName, 'lg', 'submit', pageName);

    if (externalOnClick) {
      externalOnClick(e);
    }
  };

  return (
    <StyledButton
      hasfixedheight={`${hasFixedHeight ? 'true' : 'false'}`}
      variant={variant}
      type="submit"
      block
      size="lg"
      textColor={textColor}
      backgroundColor={backgroundColor}
      disabled={isLoading}
      onClick={onClick}
      {...props}
    >
      {isLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="margin-end-3" />}
      {children}
    </StyledButton>
  );
};

export default Button;
