export const PageNamesForEvents = {
  Home: 'home',
  Pricing: 'pricing',
  PricingFinery: 'pricing_finery',
  Support: 'support',
  Profile: 'profile',
  Verify: 'verify',
  VerifyToken: 'verify_token',
  NewOrder: 'new_order',
  Orders: 'list_orders',
  OrderDetails: 'order_details',
  PricingCP: 'pricing_cp',
  PricingHC: 'pricing_hc',
  PricingP: 'pricing_p',
  PricingSC: 'pricing_sc',
  PricingWF: 'pricing_wf',
  WashmenSupport: 'washmen_support',
  NewOrderDate: 'new_order_pickup_and_dropoff',
  NewOrderInstruction: 'new_order_order_instruction',
  NewOrderLastStep: 'new_order_last_step',
  SelectService: 'select_service',
  updatePickupAndDropoff: 'update_pickup_and_dropoff',
  login: 'login',
  app: 'app',
};
