import { PageNamesForEvents } from 'constant/page-names';
import UpdatePickupOrDropoff from 'pages/orderDetails/components/update-pickup-dropoff';
import ActivePastOrders from 'pages/orders';
import Profile from 'pages/profile';
import Verify from 'pages/verify';
import { lazyWithPreload } from 'utils/bundle';

export const Home = lazyWithPreload(() => import('pages/home'));
export const NewOrder = lazyWithPreload(() => import('pages/newOrder'));
export const OrderDetails = lazyWithPreload(() => import('pages/orderDetails'));
export const Pricing = lazyWithPreload(() => import('pages/pricing'));
export const FineryPricingScreen = lazyWithPreload(() => import('pages/pricing/fineryPricingScreen'));
export const Support = lazyWithPreload(() => import('pages/support'));
export const Login = lazyWithPreload(() => import('pages/login'));

interface IProtectedRoutesProps {
  path: string;
  Component: any;
  pageName: string;
}
export const protectedRoutes: IProtectedRoutesProps[] = [
  {
    path: '/newOrder/*',
    Component: NewOrder,
    pageName: PageNamesForEvents.NewOrder,
  },
  {
    path: '/profile/*',
    Component: Profile,
    pageName: PageNamesForEvents.Profile,
  },
  {
    path: '/orders/*',
    Component: ActivePastOrders,
    pageName: PageNamesForEvents.Orders,
  },
  {
    path: '/order/details/:orderType/:orderId',
    Component: OrderDetails,
    pageName: PageNamesForEvents.OrderDetails,
  },
  {
    path: '/order/details/:orderId',
    Component: OrderDetails,
    pageName: PageNamesForEvents.OrderDetails,
  },
  {
    path: '/order/timeslots/:orderId',
    Component: UpdatePickupOrDropoff,
    pageName: PageNamesForEvents.updatePickupAndDropoff,
  },
];

export const publicRoutes = [
  {
    path: '/home',
    Component: Home,
    pageName: PageNamesForEvents.Home,
  },
  {
    path: '/support',
    Component: Support,
    pageName: PageNamesForEvents.Support,
  },
  {
    path: '/verify',
    Component: Verify,
  },
  {
    path: '/verify/:token',
    Component: Verify,
    pageName: PageNamesForEvents.VerifyToken,
  },
  {
    path: '/pricing/:type',
    Component: Pricing,
    pageName: PageNamesForEvents.Pricing,
  },
  {
    path: '/pricing/THE_FINERY',
    Component: FineryPricingScreen,
    pageName: PageNamesForEvents.PricingFinery,
  },
  {
    path: '/login/*',
    Component: Login,
    pageName: PageNamesForEvents.login,
  },
];
