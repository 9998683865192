import LogoIconFile from 'assets/icons/logo.svg';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectPartner } from 'redux-stores/slices/authSlice';
import { selectTargetTheme } from 'redux-stores/slices/uiSlice';

export { LogoIconFile };

export { ReactComponent as ActiveItemIcon } from './active-item-icon.svg';
export { ReactComponent as AddCardIcon } from './addcard-icon.svg';
export { ReactComponent as ArrowDownIcon } from './arrow-down.svg';
export { ReactComponent as ArrowRightIcon } from './arrow-right.svg';
export { ReactComponent as ArrowRightWithCircleIcon } from './arrow-right-with-circle.svg';
export { ReactComponent as ArrowUpIcon } from './arrow-up.svg';
export { ReactComponent as BackIcon } from './back.svg';
export { ReactComponent as BackArrow } from './back-arrow.svg';
export * from './careem';
export { ReactComponent as CareemPayGreenIcon } from './careem-pay-green.svg';
export { ReactComponent as CareemPayWhiteIcon } from './careem-pay-white.svg';
export { ReactComponent as ChinaFlagIcon } from './china-flag.svg';
export { ReactComponent as ClockIcon } from './clock.svg';
export { ReactComponent as CoinsIcon } from './coins.svg';
export { ReactComponent as CPBag } from './cp-bag.svg';
export { ReactComponent as CPBagSmall } from './cp-bag-small.svg';
export { ReactComponent as PantsIcon } from './cp-pants.svg';
export { ReactComponent as ShirtIcon } from './cp-shirt.svg';
export { ReactComponent as SkirtIcon } from './cp-skirt.svg';
export { ReactComponent as SuitJacketIcon } from './cp-suitjacket.svg';
export { ReactComponent as CareemPayBlackIcon } from './cpay-black.svg';
export { ReactComponent as CPlus } from './cplus.svg';
export { ReactComponent as DropOffActiveIcon } from './dropoff-active.svg';
export { ReactComponent as DropOffInactiveIcon } from './dropoff-In-active.svg';
export { getDynamicIcons } from './dynamic';
export { ReactComponent as EditIcon } from './edit.svg';
export { ReactComponent as EditNew } from './edit-new.svg';
export { ReactComponent as EditTimeIcon } from './edittime.svg';
export { ReactComponent as NoOrdersIcon } from './element-no-orders.svg';
export { ReactComponent as EmiratesFlagIcon } from './emirates-flag.svg';
export { ReactComponent as ErrorIcon } from './error.svg';
export { ReactComponent as ExclamationCircIcon } from './exclamationCircIcon.svg';
export { ReactComponent as ExpiredLinkIcon } from './expired-link-2-02-vv.svg';
export { ReactComponent as ExtraCollectionActive } from './extra-collection-active.svg';
export { ReactComponent as FineryLogo } from './finery-logo.svg';
export { ReactComponent as FranceFlagIcon } from './france-flag.svg';
export { ReactComponent as GreenCheckIcon } from './green-check-icon.svg';
export { ReactComponent as HCBag } from './hc-bag.svg';
export { ReactComponent as HCBagSmall } from './hc-bag-small.svg';
export { ReactComponent as BathrobeIcon } from './hc-bathrobe.svg';
export { ReactComponent as BedCoverIcon } from './hc-bedcover.svg';
export { ReactComponent as BedSheetIcon } from './hc-bedsheet.svg';
export { ReactComponent as BlanketIcon } from './hc-blanket.svg';
export { ReactComponent as DuvetCoverIcon } from './hc-duvet-cover.svg';
export { ReactComponent as LinensIcon } from './hc-linens.svg';
export { ReactComponent as PillowIcon } from './hc-pillow.svg';
export { ReactComponent as PillowCaseIcon } from './hc-pillowcase.svg';
export { ReactComponent as TableClothIcon } from './hc-tablecloth.svg';
export { ReactComponent as TableMatIcon } from './hc-tablemat.svg';
export { ReactComponent as TowelsIcon } from './hc-towels.svg';
export { ReactComponent as HomeCareItems } from './homecare_items.svg';
export { ReactComponent as CollapseIcon } from './icon-collapse.svg';
export { ReactComponent as CancelledIcon } from './icon-order-cancelled.svg';
export { ReactComponent as CompletedIcon } from './icon-order-completed.svg';
export { ReactComponent as CloseIcon } from './icons-14-px-close-dark.svg';
export { ReactComponent as ClockSmallIcon } from './icons-16-px-clock.svg';
export { ReactComponent as DeleteIcon } from './icons-16-px-delete.svg';
export { ReactComponent as IconSearch } from './icons-16-px-search.svg';
export { ReactComponent as MenuIcon } from './icons-24-px-menu-black.svg';
export { ReactComponent as BeddingsIcon } from './icons-34-px-beddings.svg';
export { ReactComponent as AmericanExpressIcon } from './icons-38-px-american.svg';
export { ReactComponent as ApplePayIcon } from './icons-38-px-apple.svg';
export { ReactComponent as DefaultCardIcon } from './icons-38-px-default.svg';
export { ReactComponent as MasterCardIcon } from './icons-38-px-mastercard.svg';
export { ReactComponent as PaymentIcon } from './icons-38-px-paymentcard-green.svg';
export * from './instashop';
export { ReactComponent as LaundaryInstruction } from './laundary-instruction-icon.svg';
export { ReactComponent as LocationIcon } from './location-icon.svg';
export { ReactComponent as SearchIcon } from './location-icon.svg';
export { ReactComponent as LogoIcon } from './logo.svg';
export { ReactComponent as NewPaymentProcessIcon } from './new-payment-process-icon.svg';
export { ReactComponent as NewPaymentProcessProgressIcon } from './new-payment-process-Progress.svg';
export { ReactComponent as OrderPickupActiveIcon } from './order-pickup-active.svg';
export { ReactComponent as OrderPickupInActiveIcon } from './order-pickup-in-active.svg';
export { ReactComponent as PBag } from './p-bag.svg';
export { ReactComponent as PBagSmall } from './p-bag-small.svg';
export { ReactComponent as BlouseIcon } from './p-blouse.svg';
export { ReactComponent as PantsPIcon } from './p-pants.svg';
export { ReactComponent as ShirtPIcon } from './p-shirt.svg';
export { ReactComponent as SkirtPIcon } from './p-skirt.svg';
export { ReactComponent as PendingDeliveryActiveIcon } from './pending-delivery-active.svg';
export { ReactComponent as PendingDeliveryFailedIcon } from './pending-delivery-failed.svg';
export { ReactComponent as PlayButtonIcon } from './play-button.svg';
export { ReactComponent as PlusIcon } from './plus-blue.svg';
export { ReactComponent as DarkPlusIcon } from './plus-dark-blue.svg';
export { ReactComponent as ProofOfCollectionActiveIcon } from './proof-of-collection-active.svg';
export { ReactComponent as ProofOfCollectionInActiveIcon } from './proof-of-collection-Inactive.svg';
export { ReactComponent as ProofOfDeliveryActiveIcon } from './proof-of-delivery-active.svg';
export { ReactComponent as ProofOfDeliveryInActiveIcon } from './proof-of-delivery-Inactive.svg';
export { ReactComponent as ReceiptIcon } from './receipt-icon.svg.svg';
export { ReactComponent as RightArrowIcon } from './rightarrow.svg';
export { ReactComponent as RussiaFlagIcon } from './russia-flag.svg';
export { ReactComponent as SCBag } from './sc-bag.svg';
export { ReactComponent as SelectIcon } from './select.svg';
export { ReactComponent as SelectedIcon } from './selected.svg';
export { ReactComponent as SimilingFace } from './smiling-face.svg';
export { ReactComponent as SimilingFaceWithHeart } from './smiling-face-with-heart.svg';
export { ReactComponent as SimilingFaceWithHeartEyes } from './smiling-face-with-heart-eyes.svg';
export { ReactComponent as StarIcon } from './star.svg';
export { ReactComponent as ChatIcon } from './support-chat.svg';
export { ReactComponent as UKFlagIcon } from './uk-flag.svg';
export { ReactComponent as VisaIcon } from './visa.svg';
export { ReactComponent as WashFoldItems } from './washfold_items.svg';
export { ReactComponent as WFBag } from './wf-bag.svg';
export { ReactComponent as WFBagSmall } from './wf-bag-small.svg';
export { ReactComponent as HomeWearIcon } from './wf-homewear.svg';
export { ReactComponent as SportsWearIcon } from './wf-sportswear.svg';
export { ReactComponent as CasualWearIcon } from './wf-tshirt.svg';
export { ReactComponent as UnderGarmentsIcon } from './wf-underwear.svg';
export { ReactComponent as WhatsappIcon } from './whatsappIcon.svg';

export function useDynamicIcons() {
  const targetTheme = useSelector(selectTargetTheme);
  const partnerId = process.env.REACT_APP_PARTNER_ID ?? useSelector(selectPartner);
  useEffect(() => {
    const fetchIconSetFromFile = async () => {
      try {
        const icon = targetTheme || partnerId?.toLowerCase() || 'app';
        await import(`./${icon}/style.css`);
      } catch (error) {
        // @ts-ignore
        await import(`./app/style.css`);
      }
    };
    fetchIconSetFromFile();
  }, [partnerId, targetTheme]);
}
